<template>
  <div class="new_info">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <BasicInformation ref="basicInformation" />
      </el-tab-pane>
      <el-tab-pane label="标签信息" name="second">
        <LabelInfo ref="labelInfo" />
      </el-tab-pane>
      <el-tab-pane label="手动标签" name="third">
        <LabelManagement ref="labelManagement" :newsData="Number(newsData)" />
      </el-tab-pane>
      <el-tab-pane label="半自动标签" name="four">
        <Robotizationlabel ref="robotizationlabel" :newsId="Number(newsData)" />
      </el-tab-pane>
    </el-tabs>
    <div
      style="height: 50px; background: #fff; border-top: 1px solid #dbdbdb;"
      v-if="this.activeName == 'four'"
    ></div>
    <div class="bottom" v-if="activeName !== 'four'">
      <el-button type="primary" @click="saveItem" v-if="personType"
        >修改
      </el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import BasicInformation from "./components/BasicInformation";
import LabelInfo from "./components/LabelInfo";
import LabelManagement from "./components/LabelManagement";
import Robotizationlabel from "./components/Robotizationlabel";
// 编辑资讯基本信息 / 标签信息编辑 / 标签信息查询 / 手动标签保存
import {
  editNew,
  editNewsKeywordLabel,
  getNewsKeywordLabel,
  insertHandLabel,
  editNewsInfo,
} from "@/api/news.js";
export default {
  name: "newsInfo",
  components: {
    BasicInformation,
    LabelInfo,
    LabelManagement,
    Robotizationlabel,
  },
  data() {
    return {
      newsId: "", // 数据唯一id（新增保存的id）
      activeName: "first", //tab栏绑定的名称
    };
  },
  computed: {
    // 0 新增 1 编辑 点击新增/编辑页面按钮调整路径带过来的
    personType() {
      return Number(this.$route.query.personType);
    },
    newsData() {
      return Number(this.$route.query.newsData);
    },
    newsEdit() {
      return Number(this.$route.query.newsEdit);
    },
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "third") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  created() {
    this.getNewsKeywordLabel(); //标签信息查询
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 2 && this.saveCaseDataId) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存接口
    saveItem() {
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.basicInformation.$refs.basicInformationRule.validate(
            async (valid) => {
              if (valid) {
                // this.$refs.basicInformation.newsData.informationType =
                //   this.$refs.basicInformation.newsData.informationTypes.join(
                //     ","
                //   );
                const res = await editNewsInfo(
                  this.$refs.basicInformation.newsData
                );

                if (res.code === 200) {
                  // if (res.data !== "修改成功" && res.data !== "") {
                  //   this.newsData = res.data;
                  // }
                  this.$message.success("修改成功");

                  this.activeName = "second";
                } else {
                  this.$message.error(res.msg);
                }
              }
            }
          );
          break;
        case "second":
          this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
            if (valid) {
              //最终组装的数组
              let arr = [];
              //开始添加
              this.$refs.labelInfo.newsKeywordLabelResultParam.reportKeywordAddParamList.forEach(
                (item) => {
                  let keyword = item.keyword;
                  let keywordDescribe = item.keywordDescribe;
                  let reportLabelList = [];
                  // 遍历我添加的数组
                  item.reportLabelList.forEach((child) => {
                    // 遍历总数组
                    this.$refs.labelInfo.labelOptions.forEach((item1) => {
                      if (item1.labelKeywordResultList[0].id == child.labelId) {
                        item1.labelKeywordResultList.forEach((name) => {
                          name = {
                            groupLabelId: name.groupLabelPrefix,
                            groupLabelName: name.groupLabelType,
                            labelId: name.id,
                            labelName: name.labelName,
                            labelStatus:
                              item1.labelKeywordResultList[0].id == name.id
                                ? 1
                                : 0,
                          };
                          reportLabelList.push(name);
                        });
                      }
                    });
                  });

                  let all = {
                    keyword: keyword,
                    keywordDescribe: keywordDescribe,
                    reportLabelList: reportLabelList,
                  };
                  arr.push(all);
                }
              );

              // 最终提交后端的数据
              let newsKeywordLabelResultParam = {
                keywordVersion:
                  this.$refs.labelInfo.newsKeywordLabelResultParam
                    .keywordVersion, //关键字版本
                reportId: this.newsData,
                reportKeywordAddParamList: arr,
              };
              // 判断是编辑还是新增 1编辑  0新增
              if (!this.personType) {
                // 如果未保存基本信息
                if (!this.newsData) {
                  this.$message.error(
                    "请先保存基本信息的数据再进行下一步操作！"
                  );
                } else {
                  this.$refs.labelInfo.$refs.labelInfoForm.validate(
                    async (valid) => {
                      if (valid) {
                        res = await editNewsKeywordLabel(
                          newsKeywordLabelResultParam
                        );
                        if (res.code == 200) {
                          this.$message.success("新增成功!");
                          this.activeName = "third";
                        } else {
                          this.$message.error("新增失败，请稍候重试!");
                        }
                      }
                    }
                  );
                }
              } else {
                this.$refs.labelInfo.$refs.labelInfoForm.validate(
                  async (valid) => {
                    if (valid) {
                      res = await editNewsKeywordLabel(
                        newsKeywordLabelResultParam
                      );
                      if (res.code == 200) {
                        this.$message.success("编辑成功!");
                        this.activeName = "third";
                      } else {
                        this.$message.error("编辑失败，请稍候重试!");
                      }
                    }
                  }
                );
              }
            }
          });
          break;
        case "third":
          if (!this.newsData) {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          } else {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.reportId = this.saveCaseDataId;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                insertHandLabel(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          }
          break;
        case "four":
          break;
        default:
          break;
      }
    },
    // 案例标签信息详情
    async getNewsKeywordLabel() {
      const res = await getNewsKeywordLabel({
        id: this.newsData,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.reportKeywordAddParamList) {
          this.$refs.labelInfo.newsKeywordLabelResultParam = {
            reportId: "", // 案例主键
            keywordVersion: "", // 关键字版本
            // type: null,
            reportKeywordAddParamList: [
              {
                reportLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.newsKeywordLabelResultParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.reportKeywordAddParamList.forEach((item) => {
            item.reportLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 取消按钮
    cancelItem() {
      this.$router.go(-1);
      this.$router.push({
        path: "/newsInformation",
      });
    },
  },
  // mounted() {
  //   document.getElementsByClassName("new_info")[0].style.height =
  //     document.body.clientHeight - 120 + "px";
  // },
};
</script>

<style lang="less" scoped>
.new_info {
  box-sizing: border-box;
  padding: 0 20px;
  background-color: #ffffff;
  overflow: auto;
}
#pane-first {
  height: 100% !important;
  overflow: scroll !important;
}
/deep/.el-tabs--top {
  height: 93%;
  overflow: scroll !important;
}
/deep/.el-tabs__content {
  overflow: scroll !important;
}
</style>

