<template>
  <div>
    <!--
        :rules="basicInformationRules" -->
    <div class="newsLookInfo" ref="newsLookInfo">
      <el-form
        label-width="120px"
        :model="newsData"
        v-if="isShow"
        ref="basicInformationRule"
      >
        <el-form-item label="资讯名称：">
          <el-input v-model="newsData.title"></el-input>
        </el-form-item>
        <el-form-item label="标题链接：">
          <!-- <el-input size="small" v-model="newsData.titleLink" :disabled="true"></el-input> -->
          <el-link
            :href="newsData.titleLink"
            target="_blank"
            v-if="newsData.titleLink && newsData.titleLink.length > 70"
            >{{ newsData.titleLink.substring(0, 70) }}
          </el-link>
          <el-link :href="newsData.titleLink" target="_blank" v-else
            >{{ newsData.titleLink }}
          </el-link>
        </el-form-item>
        <el-form-item label="案例图片：">
          <upload-image
            :imageUrl.sync="newsData.imgLink"
            :saveUrl.sync="newsData.coverImage"
          />
        </el-form-item>
        <el-form-item label="图片链接：">{{ newsData.imgLink }}</el-form-item>
        <el-form-item label="资讯封面图：">
          <upload-image
            :imageUrl.sync="newsData.shareImageLink"
            :saveUrl.sync="newsData.shareImage"
          />
        </el-form-item>
        <el-form-item label="图片链接：">{{
          newsData.shareImageLink
        }}</el-form-item>
        <el-form-item label="一句话：">
          <el-input v-model="newsData.informationBrief" />
        </el-form-item>
        <el-form-item label="资讯描述：">
          <Editorbar v-model="newsData.introduction" />
        </el-form-item>
        <el-form-item label="资讯来源：">{{ newsData.origin }}</el-form-item>
        <!-- 发布时间 ！ -->
        <el-form-item label="发布时间：">
          <el-input v-model="newsData.articleReleaseTime"></el-input>
        </el-form-item>
        <el-form-item label="资讯作者：">{{ newsData.author }}</el-form-item>
        <el-form-item label="发布机构：">
          <el-input v-model="newsData.enterprise" />
        </el-form-item>
        <el-form-item label="爬取网站：">{{
          newsData.crawlWebsite
        }}</el-form-item>
        <el-form-item label="爬取时间：">{{ newsData.crawlTime }}</el-form-item>
        <el-form-item label></el-form-item>
        <el-form-item label="资讯类型：">
          <el-radio-group v-model="newsData.informationType">
            <el-radio
              v-for="item in codeData"
              :label="item.codeName"
              :key="item.codeId"
              >{{ item.codeName }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="资讯附件：">
          <div
            class="file_flex"
            v-for="(el, i) in newsData.productFileList"
            :key="i"
          >
            <div
              class="name_form"
              style="
                width: 600px;
                display: flex;
                justify-content: space-between;
              "
            >
              <!-- el.name -->
              <el-input
                v-model="newsData.productFileList[i]"
                placeholder="请上传产品附件"
              />
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUploadprods"
                :http-request="
                  (res) => {
                    return uploadprods(res, i);
                  }
                "
              >
                <el-button
                  style="position: relative; left: -90px"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  plain
                  >上传附件</el-button
                >
              </el-upload>
            </div>
            <!-- <div style="display: flex; font-size: 24px">
              <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
                ><i class="el-icon-delete"></i
              ></span>
            </div> -->
          </div>
          <span style="color: #909399"
            >支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span
          >
        </el-form-item>
        <el-form-item label="关联企业：">
          <el-select
            v-model="newsData.associatedCompanyId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loadingKeyword"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.companyFullName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="editType" v-if="newsEdit"
        >修改
      </el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div> -->
  </div>
</template>

<script>
import Editorbar from "../../Editorbar";
import UploadImage from "@/components/UploadImage.vue";
import { editNew, getNewsInfo, getAllCompanyList } from "@/api/news.js";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { getDicRadioButton } from "@/api/common";
import cos from "../../../../utils/cos.js";

const defaultQueryInfo = Object.freeze({
  productFileList: [{}],
  articleReleaseTime: "",
  associatedCompanyId: "",
  author: "",
  companyName: "",
  coverImage: "",
  crawlTime: "",
  crawlWebsite: "",
  createName: "",
  creationTime: "",
  enterprise: "",
  fileUrl: "",
  id: "",
  imgLink: "",
  informationBrief: "",
  informationType: "",
  introduction: "", //资讯描述
  origin: "",
  shareImage: "",
  shareImageLink: "",
  title: "",
  titleLink: "",
  updateName: "",
  updateTime: "",
});

export default {
  name: "BasicInformation",
  components: {
    DicCheckboxGroup,
    Editorbar,
    UploadImage,
  },
  computed: {
    newsDataId() {
      return Number(this.$route.query.newsData);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    newsEdit() {
      return Number(this.$route.query.newsEdit);
    },
  },
  data() {
    return {
      productFileList: [""],
      loadingKeyword: false,
      options: [], //数智企业
      codeData: [], //资讯类型
      isShow: false,
      newsData: { ...defaultQueryInfo },
      data: "",
      queryInfo: {
        positionType: "",
      },
      // basicInformationRules: [],
    };
  },
  created() {
    this.getDicRadioButtons();
    // this.getAllCompanyList();
    if (this.personType) {
      this.viewDetails();
      setTimeout(() => {
        this.isShow = true;
      }, 500);
    } else {
      this.isShow = true;
    }
  },
  methods: {
    // 查询所有的企业
    async getAllCompanyList() {
      const res = await getAllCompanyList({ companyName: "" });
      if (res.code === 200) {
        this.options = res.data;
      }
    },
    // 远程查询
    async remoteMethod(query) {
      if (query !== "") {
        this.loadingKeyword = true;
        const res = await getAllCompanyList({ companyName: query });
        if (res.code === 200) {
          this.loadingKeyword = false;
          this.options = res.data;
        }
      } else {
        this.options = [];
      }
    },
    //上传行业动态pdf (上传文件之前的钩子，参数为上传的文件)
    async beforeUploadprods(file) {
      this.file = file;
      this.filename = file.name;
      // 要求上传格式
      let format = file.name.split(".")[1];

      let formatList = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];

      const extension = formatList.indexOf(format);
      if (extension == -1) {
        this.$message.warning("上传模板不支持此格式");
        return;
      }
      // 异步读取文件机制
      let reader = new FileReader();
      // 读取文件内容，结果用data:url的字符串形式表示
      reader.readAsDataURL(file);

      let that = this;
      // readAsDataURL 方法会读取指定的 Blob 或 File 对象，并生成data URl(base64编码)
      reader.onload = function () {
        that.fileData = reader.result;
      };
      return false;
    },
    //上传行业动态
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: "ginseng-wisdom-1300257084" /* 填写自己的 bucket，必须字段 */,
          Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
          Key:
            "system_img/" +
            params.file
              .name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
        })
        .then((res) => {
          if (res.statusCode === 200) {
            // this.detailedInfo.productFileList[index].name = res.Location;
            // vue2无法监控数组的改变
            this.$set(this.newsData.productFileList, index, res.Location);
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
          console.log("上传出错", err);
        });
    },

    //新增行业动态附件
    addUpload() {
      this.newsData.productFileList.push("");
    },
    //删除行业动态附件
    delUpload(index) {
      this.newsData.productFileList.splice(index, 1);
    },
    async getDicRadioButtons() {
      const res = await getDicRadioButton({ code: "023" });
      if (res.code == 200) {
        this.codeData = res.data;
      }
    },
    lookClickTemp(val) {
      this.newsData.imgLink = val;
    },
    saveClickTemp(val) {
      this.newsData.coverImage = val;
    },
    onChange() {},
    cancelItem() {
      this.$router.go(-1);
    },
    // 资讯查询
    async viewDetails() {
      const res = await getNewsInfo({ id: this.newsDataId });
      if (res.code == 200) {
        this.newsData = res.data;

        if (this.newsData.informationType) {
          this.newsData.informationTypes =
            this.newsData.informationType.split(",");
        } else {
          this.newsData.informationTypes = [];
        }
        if (!this.newsData.productFileList) {
          this.newsData.productFileList = [""];
        }

        // 关联企业回显
        if (res.data.associatedCompanyId) {
          this.options = [
            {
              companyFullName: res.data.companyName,
              id: res.data.associatedCompanyId,
            },
          ];
        }
        // 案例图片
        if (res.data.coverImage == '["[]"]' || res.data.imgLink == '["[]"]') {
          this.newsData.coverImage = "";
        }
        // 资讯封面图
        if (
          res.data.shareImage == '["[]"]' ||
          res.data.shareImageLink == '["[]"]'
        ) {
          this.newsData.shareImage = "";
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    // async editType() {
    //   this.newsData.informationType = this.newsData.informationTypes.join(",");
    //   const res = await editNew(this.newsData);
    //   if (res.code == 200) {
    //     this.$message.success("修改成功");
    //     this.$router.go(-1);
    //   } else {
    //     this.$message.success("修改失败");
    //   }
    // },
  },
};
</script>

<style scoped lang="less">
.file_flex {
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
/deep/ .el-form-item__label {
  box-sizing: border-box;
  padding-right: 10px !important;
}
/deep/ .el-upload--picture-card {
  width: 76px !important;
  height: 76px !important;
  line-height: 76px;
}
.newsLookInfo {
  width: 1000px;
  background-color: white;
  padding: 30px 10px 10px 10px;
  overflow: auto;

  .el-form {
    .el-form-item {
      margin-bottom: 10px;

      /deep/ .el-form-item__label {
        padding: 0;
      }

      .el-textarea {
        width: 80%;
      }

      /deep/ .el-select {
        width: 600px;
      }

      // /deep/ .el-textarea textarea{border: none;}
      /deep/ .el-textarea__inner {
        height: 100%;
      }

      /deep/ .el-input.is-disabled .el-input__inner {
        background-color: white;
        color: #606266;
        cursor: default;
        padding: 0;
      }

      /deep/ .el-textarea.is-disabled .el-textarea__inner {
        background-color: white;
        color: #606266;
        cursor: default;
      }
    }
  }

  .bottonMessage {
    p {
      color: #7c7f8e;
      font-size: 14px;
      padding-left: 100px;
    }
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;

  .el-button {
    margin: 0 20px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: grey;
}

/deep/ .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #606266;
}
</style>
