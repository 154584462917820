<template>
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from "wangeditor";
import { uploadFile } from "@/api/common";
export default {
  name: "Editorbar",
  data() {
    return {
      info: null,
      editor: "",
      isChange: false,
      fileArray: [],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info = null;
      }
    },
  },
  mounted() {
    this.seteditor();
  },
  methods: {
    seteditor() {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.uploadImgHeaders = {
        token: sessionStorage.getItem("token"),
      }; // 自定义 header
      this.editor.config.uploadFileName = "image"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      // 4.自定义控制上传过程--上传到阿里云
      this.editor.config.customUploadImg = async (files, insert) => {
        form.append("Authorization", this.Authorization);
        this.fileArray.push(params.file);

        this.fileArray.forEach((item, index) => {
          form.append("files", this.fileArray[index]);
        });
        const { data: res } = await uploadFile(form);
        if (res.code == 200) {
          this.fileArray = res.data.fileLook;
        }
      };
      // 配置菜单
      this.editor.config.menus = [
        "bold", // 粗体
        "underline", // 下划线
        "link", // 插入链接
        "quote", // 引用
        "emoticon", // 表情
        "table", // 表格
        "code", // 插入代码
      ];

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          //
          // let imgUrl = result.data;
          // insertImg(imgUrl)
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功,插入图片的回调
          var url = result.data;
          insertImg(url); //将内容插入到富文本中
        },
      };

      this.editor.config.onchange = (html) => {
        this.isChange = true;
        this.info = html; // 绑定当前逐渐地值
        this.$emit("change", this.info); // 将内容同步到父组件中
      };

      // 创建富文本编辑器
      this.editor.create();
      this.editor.txt.html(this.value);
    },
  },
};
</script>

<style lang="less" scope>
.editor {
  width: 80%;
}
.toolbar {
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  height: 300px;
}
.w-e-toolbar {
  position: static;
}
.w-e-text-container {
  position: static;
}
</style>
